<template>
  <el-card
    @click.native.stop="$emit('handleSelect')"
    @dblclick.native="handleDoubleClickOnCard"
  >
    <div
      style="display: flex; align-items: center; justify-content: space-between; padding: 0 10px"
    >
      <div style="display: flex;flex-direction: column">
        <div
          style="display: flex; margin-bottom: 4px; align-items: center"
          v-if="
            (task.task_type === 'voice' ||
              task.task_type === 'messaging' ||
              task.task_type === 'whatsapp') &&
              this.showAction(task)
          "
          :id="`task-manage-phone-numbers-img-${task.task_id}`"
          @click="$emit('handleManageNumbers')"
        >
          <img
            :src="getPhoneSvg"
            :alt="__('Phone')"
            class="phoneIcon"
            onload="SVGInject(this)"
          />
          <span
            :class="{
              phoneNumber: true,
              assign: this.getFirstPhoneNumber() === __('Assign numbers')
            }"
            >{{ this.getFirstPhoneNumber() }}</span
          >
        </div>
        <div style="display: flex; align-items: center">
          <img
            :src="getClockSvg"
            :alt="__('Phone')"
            class="phoneIcon"
            onload="SVGInject(this)"
          />
          <span class="phoneNumber"
            >{{ calls }} {{ __(getExecutionsTextBasedOnTaskType) }}</span
          >
        </div>
      </div>
      <el-dropdown
        trigger="click"
        placement="bottom-start"
        @command="handleDropDownItemClick"
        :id="`task-action-handle-${task.task_id}`"
      >
        <span @click.prevent.stop class="moreBtn"
          ><img :src="getEllipsesSvg" alt="" onload="SVGInject(this)"
        /></span>
        <el-dropdown-menu
          class="task-ops"
          :data-task-ops-id="`task-ops-${task.task_id}`"
          slot="dropdown"
        >
          <el-dropdown-item
            :command="{ action: 'Edit' }"
            v-show="this.showAction(task)"
            v-if="canWrite()"
          >
            <div :id="`task-edit-${task.task_id}`">{{ __("Edit") }}</div>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="this.showAction(task)"
            v-else
            :disabled="true"
          >
            <el-tooltip
              placement="right"
              effect="dark"
              content="You don't have permission"
            >
              <div :id="`task-edit-${task.task_id}`">
                {{ __("Edit") }}
              </div>
            </el-tooltip>
          </el-dropdown-item>
          <el-dropdown-item
            :command="{ action: 'Duplicate' }"
            :disabled="!canWrite() || !can('TASK DUPLICATE')"
          >
            <el-tooltip
              placement="right"
              effect="dark"
              :content="__('feature not provisioned')"
              v-if="!canWrite() || !can('TASK DUPLICATE')"
            >
              <div :id="`task-duplicate-${task.task_id}`">
                {{ __("Duplicate") }}
              </div>
            </el-tooltip>
            <div v-else :id="`task-duplicate-${task.task_id}`">
              {{ __("Duplicate") }}
            </div>
          </el-dropdown-item>
          <el-dropdown-item
            v-if="
              task.task_type === 'voice' ||
                task.task_type === 'messaging' ||
                task.task_type === 'whatsapp'
            "
            :command="{ action: 'ManageNumber' }"
            v-show="this.showAction(task)"
          >
            <!--            <el-tooltip-->
            <!--              placement="right"-->
            <!--              effect="dark"-->
            <!--              content="You don't have permission"-->
            <!--              v-if="!canWrite()"-->
            <!--            >-->
            <div :id="`task-manage-phone-numbers-${task.task_id}`">
              {{ __("Manage Phone Numbers") }}
            </div>
            <!--            </el-tooltip>-->
            <!--            <div v-else>Manage Phone Numbers</div>-->
          </el-dropdown-item>
          <!--          <el-dropdown-item v-if="task.task_type === 'voice' || task.task_type === 'messaging'"-->
          <!--            >Call out-->
          <!--          </el-dropdown-item>-->
          <el-dropdown-item
            :command="{ action: 'CheckInUse' }"
            v-show="this.showAction(task)"
          >
            <div :id="`task-check-in-use-${task.task_id}`">
              {{ __("Check in use") }}
            </div>
          </el-dropdown-item>
          <el-dropdown-item
            :style="!canWrite() ? '' : 'color: red'"
            :command="{ action: 'Delete' }"
            :disabled="!canWrite()"
            v-show="this.showAction(task)"
          >
            <el-tooltip
              placement="right"
              effect="dark"
              :content="__('You don\'t have permission')"
              v-if="!canWrite()"
            >
              <div :id="`task-delete-${task.task_id}`">{{ __("Delete") }}</div>
            </el-tooltip>
            <div v-else :id="`task-delete-${task.task_id}`">
              {{ __("Delete") }}
            </div>
          </el-dropdown-item>
          <el-dropdown-item
            :disabled="!can('TASK EXPORT')"
            :command="{ action: 'Export' }"
          >
            <el-tooltip
              placement="right"
              effect="dark"
              :content="__('feature not provisioned')"
              v-if="!can('TASK EXPORT')"
            >
              <div :id="`task-export-${task.task_id}`">{{ __("Export") }}</div>
            </el-tooltip>
            <div v-else :id="`task-export-${task.task_id}`">
              {{ __("Export") }}
            </div>
          </el-dropdown-item>
          <el-dropdown-item
            :disabled="
              !can('discover.task-library.write') || !can('TEMPLATE PUBLISHING')
            "
            :command="{ action: 'Publish' }"
            v-if="userHasSPScopeToAc && can('discover.task-library.write')"
          >
            <el-tooltip
              placement="right"
              effect="dark"
              :content="__('feature not provisioned')"
              v-if="
                !can('discover.task-library.write') ||
                  !can('TEMPLATE PUBLISHING')
              "
            >
              <div :id="`task-publish-${task.task_id}`">
                {{ __("Publish") }}
              </div>
            </el-tooltip>
            <div v-else :id="`task-publish-${task.task_id}`">
              {{ __("Publish") }}
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div style="display: flex; position: relative">
      <div style="display: flex; flex-direction: column;width: 100%">
        <img
          :src="getTaskSvg(task.task_image)"
          alt="taskImg"
          height="100px"
          width="100%"
          style="padding-top: 10px"
        />
        <div
          style="display: flex; justify-content: center;margin-top: 10px;padding: 0 20px;"
        >
          <div
            class="task-card-names"
            style="white-space: nowrap; font-size: 11px;   font-weight: lighter;"
          >
            {{ task.iva_collection_name }}
          </div>
        </div>
        <div
          style="display: flex; justify-content: center;margin-top: 0;padding: 0 20px;"
        >
          <div
            class="task-card-names"
            style="font-size: 14px; font-weight: bold"
          >
            {{ task.task_name }}
          </div>
        </div>
      </div>
      <div class="cardOverlay" :class="{ locked: task.protected }">
        <div
          style="display: flex; flex-direction: column; height: 100%; padding: 0 10px"
        >
          <el-button
            :id="`task-view-canvas-${task.task_id}`"
            @click="openCallFlowEditor"
            class="viewCanvasBtn"
          >
            {{ __("View Canvas") }}
          </el-button>
          <div
            style="display: flex; justify-content: center;margin-top: 10px;flex-wrap: wrap"
          >
            <span
              v-for="(tag, index) in getTagsArray(task.tags)"
              :key="`${tag}_${index}`"
              class="tag"
            >
              {{ tag }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; flex-grow: 1"></div>
    <div style="display: flex; padding: 0 10px;justify-self: flex-end">
      <div
        style="display: flex; margin-bottom: 4px; justify-content: space-between; width: 100%"
      >
        <el-button
          style="padding: 0; border: none"
          plain
          class="unlockIcon"
          :id="`task-unlock-${task.task_id}`"
          v-show="!task.protected && can('build.tasks.lock')"
          @click.stop="handleTaskLockClick"
          ><img :src="getUnlockedIcon" alt="" onload="SVGInject(this)"
        /></el-button>
        <el-button
          style="padding: 0; border: none"
          plain
          class="lockIcon"
          :id="`task-lock-${task.task_id}`"
          v-show="task.protected"
          :disabled="!can('build.tasks.lock')"
          @click.stop="handleTaskLockClick"
          ><img :src="getLockedIcon" alt="" onload="SVGInject(this)"
        /></el-button>
        <el-button
          plain
          style="padding: 0; border: none"
          @click.stop="$emit('updateTaskFavorite')"
          class="starIcon"
          :id="`task-favorite-${task.task_id}`"
          :class="{ is_favorite: task.is_favorite }"
          ><img :src="getIconUnStarred" alt="" onload="SVGInject(this)"
        /></el-button>
      </div>
    </div>
  </el-card>
</template>

<script>
// import { exportTask } from "@/api/tasks";
import FileDownloader from "@/mixins/FileDownloader";
import { mapGetters } from "vuex";
// import _ from "lodash";

export default {
  mixins: [FileDownloader],
  props: {
    task: {
      required: true,
      type: [Object, Array]
    },
    calls: {
      required: false,
      type: Number,
      default: 443
    }
  },
  computed: {
    ...mapGetters("app", {
      userHasSPScopeToAc: "userHasSPScopeToAc"
    }),
    getPhoneSvg() {
      return require("@/assets/icons/icon-phone.svg");
    },
    getClockSvg() {
      return require("@/assets/icons/icon-clock.svg");
    },
    getEllipsesSvg() {
      return require("@/assets/icons/icon-ellipses.svg");
    },
    getIconUnStarred() {
      return require("@/assets/icons/icon-unstarred.svg");
    },
    getUnlockedIcon() {
      return require("@/assets/icons/icon-lock-open.svg");
    },
    getLockedIcon() {
      return require("@/assets/icons/icon-lock-close.svg");
    },
    getExecutionsTextBasedOnTaskType() {
      let mappings = {
        voice: "active call",
        messaging: "message",
        workflow: "execution",
        whatsapp: "message",
        chatbot: "message"
      };
      if (this.calls > 1) {
        return mappings[this.task.task_type] + "s";
      }
      return mappings[this.task.task_type];
    },
    showAction() {
      return taskInfo => {
        if (!taskInfo.protected) {
          return true;
        } else return !(taskInfo.protected && !this.can("build.tasks.lock"));
      };
    }
  },
  methods: {
    openCallFlowEditor() {
      this.$router.push({
        name: "callflow",
        params: { task_id: this.task.task_id }
      });
    },

    handleClick(ev) {
      console.log(ev);
    },
    getTagsArray(tags) {
      if (tags) {
        return tags.length > 0 ? tags.split(",", 4) : [];
      }
    },
    getTaskSvg(taskImage) {
      if (taskImage) {
        return require("@/assets/images/taskImages/" + taskImage);
      }
      return require("@/assets/images/taskImages/task_image_placeholder.svg");
    },
    getFirstPhoneNumber() {
      if (this.task.phone_number && this.task.phone_number.length > 0) {
        return this.task.phone_number[0].phone_number;
      } else {
        return __("Assign numbers");
      }
    },
    handleDropDownItemClick({ action }) {
      switch (action) {
        case "Edit":
          this.$emit("handleEdit");
          break;
        case "Delete":
          this.$emit("handleDelete");
          break;
        case "CheckInUse":
          this.$emit("handleCheckItemInUse");
          break;
        case "Duplicate":
          if (this.canWrite() && this.can("TASK DUPLICATE")) {
            this.$emit("duplicate", this.task, action);
          }
          break;
        case "Export":
          if (this.can("TASK EXPORT")) {
            this.$emit("initiateExport", this.task, action);
          }
          break;
        case "Publish":
          if (this.canWrite() && this.can("TEMPLATE PUBLISHING")) {
            this.$emit("handleTaskPublish");
          }
          break;
        case "ManageNumber":
          if (this.canRead()) {
            this.$emit("handleManageNumbers");
          }
          break;

        default:
      }
    },
    handleDoubleClickOnCard() {
      if (this.showAction(this.task)) {
        this.$emit("handleEdit");
      }
    },
    handleTaskLockClick() {
      if (this.showAction(this.task)) {
        this.$emit("updateTaskLock", this.task);
      }
    }
  }
};
</script>

<style lang="scss">
.task-ops {
  .is-disabled {
    color: #a0a8b5 !important;
    pointer-events: all !important;
  }
}
.task-card-names {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
  padding-bottom: 2px;
}
</style>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;

.cardContainer {
  user-select: none;
}

.cardContainer.selected {
  border: $content-theme-color 2px solid;
}

.phoneIcon {
  width: 20px;
  height: 20px;
  fill: $content-theme-color;

  ::v-deep path {
    fill: $content-theme-color;
  }
}

.starIcon {
  background: transparent;
  opacity: 0;

  &:hover {
    background: transparent;
  }

  &:active {
    background: transparent;
  }

  /*::v-deep svg {
    width: 20px;
    height: 20px;
    fill: transparent;
    transition: fill 0.5s ease;
  }*/
  ::v-deep svg {
    width: 20px;
    height: 20px;
    path {
      stroke: $content-theme-color !important;
    }
    fill: transparent;
    transition: fill 0.5s ease;
  }

  &.is_favorite {
    opacity: 1;

    svg {
      fill: $content-theme-color;
      transition: fill 0.5s ease;
    }
  }
}

.unlockIcon,
.lockIcon {
  background: transparent;
  margin-left: 0;

  &:hover {
    background: transparent;
  }

  &:active {
    background: transparent !important;
  }

  ::v-deep svg {
    width: 20px;
    height: 20px;
    fill: var(--theme-color) !important;
    path {
      fill: var(--theme-color) !important;
    }
  }
}

.unlockIcon {
  opacity: 0;
}

.phoneNumber {
  padding-left: 5px;
  font-weight: bold;
  font-size: 14px;
  color: #a0a8b5;
  &.assign {
    color: $content-theme-color;
    &:hover {
      color: $content-theme-hover-color;
    }
  }
}

.moreBtn {
  background: transparent;
  color: $content-theme-color;
  outline: none;
  border: none;
  padding: 0;

  ::v-deep svg {
    fill: $content-theme-color;

    path {
      fill: $content-theme-color;
    }
  }
}

.cardOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0;

  &.locked {
    background: #f5f5f5;
  }

  transition: opacity 0.5s ease;
}

.viewCanvasBtn {
  width: 100%;
  height: 44px;
  border: none;
  color: white;
  background: $content-theme-color;
  margin-top: 50px;

  &:hover {
    background: $content-theme-hover-color;
    color: white;
  }
}

.tag {
  background: #f5f5f5;
  padding: 5px;
  color: #a0a8b5;
  margin: 3px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  border: #a0a8b5 1px solid;
}

::v-deep .el-dropdown-menu__item {
  font-size: 1rem;
  color: #696969;
}

.el-card {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin: 10px;
  height: 270px;
  min-width: 239px;
  max-width: 239px;
  display: flex;
  border: 1px solid transparent;
  border-radius: 5px;

  &.selected {
    border: 1px solid $content-theme-color;
  }

  &.locked {
    box-shadow: none;
    background: #f5f5f5;
  }

  &:hover {
    .unlockIcon {
      opacity: 1;
    }

    .starIcon {
      opacity: 1;
    }

    .cardOverlay {
      opacity: 1;
      transition: opacity 0.5s ease;
    }
  }

  ::v-deep .el-card__body {
    display: flex;
    padding: 10px 0;
    width: 100%;
    flex-grow: 0;
    flex-direction: column;
  }
}
</style>
